import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CreateAccountPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to parse and extract the relevant error message part
  const parseErrorMessage = (errorMessage) => {
    // Regular expression to match everything after the colon ":"
    const regex = /:\s*(.*)/; // Match everything after the colon and space
    const match = errorMessage.match(regex);
    if (match && match[1]) {
      return match[1].trim(); // Return only the part after the colon
    }
    return errorMessage; // If no match, return the original message
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear any previous error messages

    // Basic client-side validation
    if (!username || !password || !email) {
      setMessage("Please fill in all fields.");
      return;
    }

    // Basic email format validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/signup`;

      // Send POST request to API with signup details
      const response = await axios.post(apiUrl, { username, password, email });

      setMessage(response.data.message || "Account created successfully.");
    } catch (error) {
      let errorMessage = "Error creating account. Please try again.";

      if (error.response) {
        // If the error is from the backend, try to parse the error message
        const parsedMessage = error.response.data?.message
          ? parseErrorMessage(error.response.data.message)
          : "An unexpected error occurred. Please try again.";

        // Handle specific error cases
        if (error.response.status === 400) {
          errorMessage =
            parsedMessage || "Bad request. Please check your input.";
        } else if (error.response.status === 409) {
          // If we have a conflict (i.e., user already exists)
          errorMessage =
            parsedMessage ||
            "Username or email already exists. Please choose a different one.";
        } else if (
          error.response.data?.errorCode === "InvalidPasswordException"
        ) {
          // Extract the specific password error message
          errorMessage = "Password must have uppercase characters.";
        } else if (error.response.status === 500) {
          errorMessage = "Server error. Please try again later.";
        } else {
          // For other unknown errors, display the parsed message
          errorMessage = parsedMessage;
        }
      } else if (error.request) {
        errorMessage =
          "Network error: Could not reach the server. Please check your connection.";
      } else {
        errorMessage = `Unexpected error: ${error.message}`;
      }

      setMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-account-page">
      <h2>Create Account</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Creating Account..." : "Create Account"}
        </button>
      </form>
      {message && <p className="error-message">{message}</p>}
      <p>
        Already have an account? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
};

export default CreateAccountPage;
