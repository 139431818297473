import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  // Check if user is logged in on page load
  useEffect(() => {
    const token = localStorage.getItem("idToken");
    const user = localStorage.getItem("username");
    if (token && user) {
      setIsLoggedIn(true);
      setUsername(user);
    }
  }, []);

  const handleLogout = () => {
    // Remove idToken and username from local storage
    localStorage.removeItem("idToken");
    localStorage.removeItem("username");

    setIsLoggedIn(false);
    setUsername(""); // Clear username
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="container">
      <h1>Welcome to Account Management</h1>

      {isLoggedIn ? (
        <div>
          <h2>Hello/Hallo/Hola, {username}!</h2>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <p>
          Please <Link to="/login">Login</Link> or{" "}
          <Link to="/create-account">Create an Account</Link>.
        </p>
      )}
    </div>
  );
};

export default HomePage;
